import React, { useState } from "react"
import * as Images from "../../../utilities/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { adminLogout } from "../../../redux/slices/auth";
import { useDispatch } from 'react-redux';
import { searchKey } from "../../../redux/slices/users";
import { searchKeyArtcile } from "../../../redux/slices/helpDesk";

const SideNav = () => {
    const dispatch = useDispatch();
    const [active, setActive] = useState("")
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;

    // admin logout
    const logoutAdmin = (e) => {
        e.preventDefault();
        dispatch(adminLogout({
            cb(res) {
                if (res?.data) {
                    navigate("/")
                }
            }
        }))
    }

    //reset input
    const onChangeReset = () => {
        dispatch(searchKey({ enterpriseSearchKey: "" }))
        dispatch(searchKeyArtcile({ articleSearchKey: "" }))
    }

    return (
        <div className='containerBox adminSidebar'>
            <h3 className='headTxtBlueSmall mb-5'>Menu</h3>
            <ul className='settingMenus'>
                <li
                    className='sidebarItems'>
                    <Link to='dashboard' className={`sidebarLinks ${active === "Dashboard" ? "active" : "sidebarLinks"}`} onClick={() => {
                        setActive("Dashboard")
                        onChangeReset()
                    }} >
                        <img src={Images.dashboard} alt='dashboard' />
                        <span>Dashboard</span>
                    </Link>
                </li>
                {/* <li
                    className='sidebarItems'>
                    <Link to='#' className={`sidebarLinks ${active === "Analytics" ? "active" : "sidebarLinks"}`} onClick={() => setActive("Analytics")}>
                        <img src={Images.anaylicsIcon} alt='analytics' />
                        <span>Analytics</span>
                    </Link>
                </li> */}
                {/* <li
                    className='sidebarItems'>
                    <Link to='#' className={`sidebarLinks ${active === "Financial" ? "active" : "sidebarLinks"}`} onClick={() => setActive("Financial")}>
                        <img src={Images.financialIcon} alt='financial' />
                        <span>Financial</span>
                    </Link>
                </li> */}
                {/* <li
                    className='sidebarItems'>
                    <Link to='#' className={`sidebarLinks ${active === "Advertising" ? "active" : "sidebarLinks"}`} onClick={() => setActive("Advertising")}>
                        <img src={Images.promoIcon} alt='promo codes' />
                        <span>Advertising</span>
                    </Link>
                </li> */}
                {/* <li
                    className='sidebarItems'>
                    <Link to='#' className='sidebarLinks'>
                        <img src={Images.kybIcon} alt='kyb' />
                        <span>KYB</span>
                    </Link>
                </li> */}
                {/* <li
                    className='sidebarItems'>
                    <Link to='#' className={`sidebarLinks ${active === "Security" ? "active" : "sidebarLinks"}`} onClick={() => setActive("Security")}>
                        <img src={Images.kybIcon} alt='kyb' />
                        <span>Security</span>
                    </Link>
                </li> */}
                <hr />
                {/* <li
                    className='sidebarItems'>
                    <Link to='#' className={`sidebarLinks ${active === "User List" ? "active" : "sidebarLinks"}`} onClick={() => setActive("User List")}>
                        <img src={Images.membershipIcon} alt='members list' />
                        <span>User List</span>
                    </Link>
                </li> */}



                <li
                    className='sidebarItems'>
                    <Link to='/add-user' className={`sidebarLinks ${active === "Enterprise Signup" ? "active" : "sidebarLinks"}`} onClick={() => {
                        setActive("Enterprise Signup")
                        onChangeReset()
                    }}>
                        <img src={Images.enterpriseMember} alt='message center' />
                        <span>Enterprise Signup</span>
                    </Link>
                </li>
                <li
                    className='sidebarItems'>
                    <Link to='/users' className={`sidebarLinks ${active === "Enterprise Users List" ? "active" : "sidebarLinks"}`} onClick={() => {
                        onChangeReset()
                        setActive("Enterprise Users List")
                    }}>
                        <img src={Images.memberList} alt='message center' />
                        <span>Enterprise User List</span>
                    </Link>
                </li>
                <li
                    className='sidebarItems'>
                    <Link to='/help-desk' className={`sidebarLinks ${active === "Help Desk" ? "active" : "sidebarLinks"}`} onClick={() => {
                        onChangeReset()
                        setActive("Help Desk")
                    }}>
                        <img src={Images.helpDesk} alt='message center' />
                        <span>Help Desk</span>
                    </Link>
                </li>
                {/* <li
                    className='sidebarItems'>
                    <Link to='/support'  className={`sidebarLinks ${active === "User Support" ? "active" : "sidebarLinks"}`} onClick={() => setActive("User Support")}> 
                        <img src={Images.messageIcon} alt='message center' />
                        <span>User Support</span>
                    </Link>
                </li> */}
                <hr />
                {/* <li
                    className='sidebarItems'>
                    <Link to='#' className={`sidebarLinks ${active === "Settings" ? "active" : "sidebarLinks"}`} onClick={() => setActive("Settings")}>
                        <img src={Images.settingIcon} alt='setting Icon' />
                        <span>Settings</span>
                    </Link>
                </li> */}
                <li
                    className='sidebarItems'>
                    <Link to='#' className={`sidebarLinks ${active === "Logout" ? "active" : "sidebarLinks"}`} onClick={() => setActive("Logout")}>
                        <img src={Images.logout} alt='message center' />
                        <span onClick={(e) => logoutAdmin(e)}>Logout</span>
                    </Link>
                </li>
            </ul>
            {
                path == "/support" ?
                    <div className='advData my-5'>
                        <h4 className='headingSmall_ mb-3'>Advertising Data</h4>
                        <p className='headingTitleLight_'>Advertising data is a dataset that is made up of one the largest range of information as far as data is concerned. In the world of business, advertising forms the basis upon which businesses are built and transformed for growth</p>
                    </div> :
                    <div className='advData my-5'>
                        <h4 className='headingSmall_ mb-3'>This is the administratrive <br /> Dashboard</h4>
                        <p className='headingTitleLight_'>This is where you can manage all of the members, see their activity.</p>
                    </div>
            }
        </div >


    )

}

export default SideNav;