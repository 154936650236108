import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Images from "../../../utilities/images";
import { useDispatch } from 'react-redux';
import { getUsersList,searchKey } from '../../../redux/slices/users';
import ReactPaginate from "react-paginate";
import moment from "moment";
import { useUserSelector } from '../../../redux/selector/users';

const EnterpriseUsers = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userSelector = useUserSelector()
    const [search, setSearch] = useState(userSelector?.enterpriseSearchKey)
    const [pageLimit, setPageLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState();
    const [listing, setListing] = useState([])

    const getListing = (page, limit) => {
        let params = {
            page: page ? page : currentPage,
            limit: limit ? limit : pageLimit,
            search: search ? search : "",
        }
        dispatch(searchKey({enterpriseSearchKey: search}))
        dispatch(getUsersList({
            ...params, cb(res) {
                if (res.data) {
                    setListing(res?.data?.payload?.data)
                    setTotalPage(res?.data?.payload?.total_pages)
                }
            }
        }))
    }

    //on change page pagination
    const handlePageClick = (e) => {
        const pageNo = e.selected + 1;
        setCurrentPage(pageNo);
        getListing(pageNo, pageLimit)
    };

    const handelEdit = (id) => {
        if (id) {
            navigate(`/edit-user/${id}`)
        }
    }

    //reset input
    const onChangeReset = () => {
        setSearch("")
        dispatch(searchKey({enterpriseSearchKey: ""}))

    }

    console.log(search, "search check");

    useEffect(() => {
        getListing()
    }, [search])

    useEffect(() => {
        getListing()
    }, [])

    return (
        <>
            <div className='containerBox adminDashbord'>
                <h3 className='headTxtBlueSmall mb-3'>Enterprise Users</h3>
                <div className='searchFilter mt-5'>
                    <div className='selectBoxs'>
                        {/* <select className='supportFilter me-3' value={articleCategoryId} onChange={(e) => setArticleCategoryId(e.target.value)}>
                            <option disabled value="">Search Category</option>
                            <option value="">All</option>
                            {articleCategory && articleCategory?.map((val, index) => {
                                return <option value={val?.id} key={index}>{val?.name}</option>
                            })}
                        </select> */}
                        <div className='searchByKeywordOuter'>
                            <input type='text' value={search} className='supportFilter' placeholder='Search By Keyword' onChange={(e) => setSearch(e.target.value)} />
                            {
                                search !== ""   ?
                                    <button className="inputClose " type="reset" onClick={() => {
                                        onChangeReset()
                                    }}> <i className="las la-times"></i> </button>
                                    : search === undefined ? "" : ""
                            }
                        </div>
                    </div>
                    {/* <Link to="/add-user" className='postBtn'>Add New User</Link> */}
                </div>
                <table className='manageUserTable mt-5'>
                    <thead>
                        <tr>

                            <th>Date</th>
                            <th>Name</th>
                            <th>Company Name</th>
                            <th>Email</th>
                            <th>Plan</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    {
                        userSelector?.loading ? <div className="row">
                            <div className="col-sm-12 text-center pt-5 pb-5">
                                <div className="spinner-grow text-success" role="status"></div>
                            </div>
                        </div>
                            :
                            <>
                                {
                                    listing?.length > 0 ?
                                        <tbody>
                                            {listing?.map((data, index) => (
                                                <tr key={index}>
                                                    {/* <td>{data?.title}</td> */}
                                                    {console.log(data, "data of enmail")}
                                                    <td>{moment(data?.updated_at).format('MMM D, YYYY')}</td>
                                                    <td>{data?.user_profile?.first_name} {data?.user_profile?.last_name}</td>
                                                    <td>{data?.user_company_information?.company_name}</td>
                                                    <td>{data?.email}</td>
                                                    <td>{data?.membership_details?.name}</td>
                                                    <td>
                                                        <img src={Images.editIcon} alt="icon" className='trashIcon me-2' onClick={() => handelEdit(data?.id)} />
                                                        {/* <img src={Images.trashIcon} alt="icon" className='trashIcon' onClick={() => handleDelete(data?.id)} /> */}
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody> :
                                        <span className='text-center'>No data found</span>
                                }
                            </>
                    }
                </table>
                <div className="col-12 ">
                    <div className="customPagination mt-4">
                        <div className="pagination">
                            {listing?.length > 0 ? (
                                <ReactPaginate
                                    // nextLabel={'Next ' + <span>{'>>'}</span>}
                                    nextLabel={<span>
                                        Next <span className="double-arrow">{">>"}</span>
                                    </span>}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    // pageRangeDisplayed={pageCount}  // Show only the starting 2 and last 2 pages
                                    marginPagesDisplayed={2}
                                    // pageRangeDisplayed={pageCount}
                                    pageCount={totalPage}
                                    // previousLabel={'<< Previous'}
                                    previousLabel={<span>
                                        <span className="double-arrow h5">{"<<"}</span> Previous
                                    </span>}
                                    renderOnZeroPageCount={null}
                                />
                            ) : ""}
                        </div>
                    </div>
                </div>

                {/* :<tr><td colSpan={5}><p className='text-center'> <b>No Users Found</b></p></td></tr> */}

            </div>
        </>
    )
}

export default EnterpriseUsers