import React, { useState, useCallback, useEffect } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify"
import { useDispatch } from 'react-redux';
import Multiselect from "multiselect-react-dropdown";
import {
  businessOwnershipType,
  companyLogoUplaod,
  companyLogoUpload,
  diverseSupplierDescription,
  existingCertification,
  getAllIndustryGroups,
  getAllIndustrySubGroups,
  getPlans,
  userAdd,
} from "../../../redux/slices/users";
import { useUserSelector } from '../../../redux/selector/users';

const AddEnterpriseUser = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userSelector = useUserSelector()
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    companyName: "",
    roleId: "",
    plan_id: "",
    paymentstatus: ""
  });
 
  const [passwordShown, setPasswordShown] = useState(false);
  const [planList, setPlanList] = useState([])

  //onchange input 
  const handleChange = (e, fieldname) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  // onchange input
  const handleChangeIndustry = (id, flag) => {
    // if (flag == "industry") {
    //   setFormData({
    //     ...formData,
    //     industryGroupId: id
    //   })
    //   subIndustry(id);
    // } else if (flag == "subindustry") {
    //   setFormData({
    //     ...formData,
    //     industrySubGroupId: id
    //   })
    // }
    // else {
    setFormData({
      ...formData,
      plan_id: id
    })
    // }
  };

  const plans = (e) => {
    dispatch(
      getPlans({
        cb(res) {
          if (res.status) {
            setPlanList(res?.data?.payload)
          } else {
          }
        },
      })
    );
  };


  //password field show or hide
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    let letterVal = /^[a-zA-Z\s]*$/;
    if (!formData.plan_id) {
      toast.error("Please select plan");
      return
    }
    // else if (!formData.paymentstatus) {
    //   toast.error("Please choose payment status");
    //   return
    // }
    else if (!formData.companyName || formData.companyName.trim() === "") {
      toast.error("Please enter Company name");
      return;
    } else if (formData.companyName.length <= 2) {
      toast.error("Company name should be minimum 3 character");
      return;
    }
    else if (!formData.email) {
      toast.error("Please enter email");
      return;
    }
    else if (formData.email && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(formData.email)) {
      toast.error("Please enter valid email address");
      return;
    }
    else if (!formData.password) {
      toast.error("Please enter password");
      return;
    }
    else if (!/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/.test(formData.password)) {
      toast.error("Password must be at least 8 characters long with 1 capital letter, 1 number and 1 special character");
      return;
    }
    else if (!formData.roleId) {
      toast.error("Please Select Role");
      return
    }
    else if (!formData.firstName || formData.firstName.trim() === "" || formData.firstName.length < 2) {
      toast.error("Please enter first name");
      return;
    }
    else if (!formData.firstName.match(letterVal)) {
      toast.error('Please enter in first name alphabet characters only')
      return;
    }
    else if (!formData.lastName || formData.lastName.trim() === "" || formData.lastName.length < 2) {
      toast.error("Please enter last name");
      return;
    }
    else if (!formData.lastName.match(letterVal)) {
      toast.error('Please enter in last name alphabet characters only')
      return;
    }
    let params = {
      plan_id: formData.plan_id,
      // is_enterprise_payment_done: parseInt(formData.paymentstatus) ? parseInt(formData.paymentstatus) : 0,
      is_enterprise_payment_done: 0,
      company_name: formData.companyName,
      email: formData.email.trim(),
      password: formData.password,
      company_name: formData.companyName,
      role_id: formData.roleId,
      business_type: 1,
      first_name: formData.firstName,
      last_name: formData.lastName,
    };
    dispatch(
      userAdd({
        ...params,
        cb(res) {
          navigate("/users")
          console.log(res, "reseponse of users");
          toast.success(res?.data?.msg)
        },
      })
    );
  }

  useEffect(() => {
    plans()
  }, [])


  return (
    <>
      <div className='containerBox adminDashbord'>

        <div className='searchFilter'>
          <h3 className='headTxtBlueSmall mb-3'>Enterprise User</h3>
          {/* <Link to="/users" className='postBtn'>Go Back</Link> */}
        </div>
        <form className='form mt-5' onSubmit={(e) => handleSubmit(e)}>
          <div className='row'>
            <div className="form-group col-md-6 mb-3">
              <label for="" className='customLabel'>Membership Level <span className='fieldMandatory'>*</span></label>
              <select
                className="cateSelectbox"
                required=""
                value={formData.plan_id}
                onChange={(e) => {
                  handleChangeIndustry(e.target.value, "");
                }}
              >
                <option value="">
                  Select Plans
                </option>
                {planList?.length > 0 ? (
                  <>
                    {planList?.map((data) => {
                      return (
                        <option value={data?.id}>
                          {data?.name}
                        </option>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </select>
            </div>
            <div className="form-group col-md-6 mb-3">
              <label for="" className='customLabel'>Account Activation <span className='fieldMandatory'>*</span></label>
              {/* <select
                className="cateSelectbox"
                onChange={((e) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    paymentstatus: e.target.value
                  }));
                })}
                value={formData.paymentstatus}
              >
                <option value="0" selected disabled>Account Pending Payment</option>
              </select> */}
              <input
                name="companyName"
                type="text"
                className="customFormControl"
                placeholder="Account Pending Payment"
                disabled
              // disabled={companyTypeId}
              />
            </div>
            <div className="form-group col-md-6 mb-3">
              <label for="" className='customLabel'>Company Name <span className='fieldMandatory'>*</span></label>
              <input
                name="companyName"
                type="text"
                className="customFormControl"
                value={formData.companyName}
                placeholder="Company Name "
                onChange={(e) => handleChange(e)}
              // disabled={companyTypeId}
              />
            </div>
            <div className='form-group col-md-6 mb-3'>
              <label for="" className='customLabel'>Email Address <span className='fieldMandatory'>*</span></label>
              <input onChange={(e) => handleChange(e)} value={formData.email} autoComplete="off" name="email" type='email' className='customFormControl' placeholder='Company Email Address ' />
            </div>
            <div className='form-group col-md-12 mb-3 position-relative'>
              <label for="" className='customLabel'>Password <span className='fieldMandatory'>*</span></label>
              <input onChange={(e) => handleChange(e)} type={passwordShown ? "text" : "password"} value={formData.password} autoComplete="off" name="password" className='customFormControl' placeholder='Password ' />
              <span className="toggle_password_ info_icon" onClick={() => { togglePasswordVisiblity(!passwordShown) }}>
                <span className={passwordShown ? "show-icon togglePassword" : "hide-icon togglePassword"} id=""></span>
              </span>
            </div>
            <div className="form-group col-md-12 mb-3">
              <label for="" className='customLabel'>Role <span className='fieldMandatory'>*</span></label>
              <select
                className="cateSelectbox"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    roleId: e.target.value
                  })
                }}
                value={formData.roleId}
              >
                <option value="" disabled>
                  I am a ..
                </option>
                <option value="1">Buyer</option>
                <option value="2">Supplier</option>
              </select>
            </div>
            <div className='form-group col-md-6 mb-3'>
              <label for="" className='customLabel'>First Name <span className='fieldMandatory'>*</span></label>
              <input id="" onChange={(e) => handleChange(e)} maxLength={25} value={formData.firstName} name="firstName" type='text' className='customFormControl' placeholder='First Name ' />
            </div>
            <div className='form-group col-md-6 mb-3'>
              <label for="" className='customLabel'>Last Name <span className='fieldMandatory'>*</span></label>
              <input onChange={(e) => handleChange(e)} maxLength={25} value={formData.lastName} name="lastName" type='text' className='customFormControl' placeholder='Last Name ' />
            </div>








          </div>
          <div className='col-12 text-end mt-5'>
            <button className='submitBtn  mt-2 d-inline' type="submit">
              {userSelector?.loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default AddEnterpriseUser